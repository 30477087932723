<template>
  <Card
    :icon-image="images.affiliateIcon"
    :title="$t('landing-page.views.sparkaffiliates-landing-page.index.cards.affiliate.title')"
    :subtitle="$t('landing-page.views.sparkaffiliates-landing-page.index.cards.affiliate.subtitle')"
    :button-text="$t('landing-page.views.sparkaffiliates-landing-page.index.cards.affiliate.button')"
    button-action="enableAffiliationFeature"
    @enableAffiliationFeature="emitClickAction"
  />
</template>

<script>
import Card from './shared/Card';

export default {
  name: 'AffiliateCard',
  components: {
    Card,
  },
  data() {
    return {
      images: {
        affiliateIcon: require('@/assets/images/SparkAffiliates/LandingPage/affiliate-icon.svg'),
      },
    };
  },
  methods: {
    emitClickAction() {
      this.$emit('enableAffiliationFeature');
    },
  },
};
</script>
