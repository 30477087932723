<template>
  <div
    class="tlw-text-center tlw-flex tlw-mb-6 tlw-flex-col tlw-w-full tlw-bg-white tlw-shadow-lg tlw-rounded-lg tlw-p-6"
  >
    <img
      :src="iconImage"
      width="68"
      height="68"
      class="tlw-self-center tlw--mt-14 tlw-mb-6"
      :class="[{ 'affiliate-card__icon': disabled }]"
      alt="Personagem"
    />
    <div class="tlw-text-xl tlw-font-bold tlw-mb-4">
      {{ title }}
    </div>
    <div class="tlw-mb-6">
      {{ subtitle }}
    </div>
    <hs-button class="tlw-pl-0" :variant="variant" @click="$emit(buttonAction)" :disabled="disabled">
      {{ buttonText }}
    </hs-button>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    iconImage: String,
    title: String,
    subtitle: String,
    buttonText: String,
    buttonAction: String,
    variant: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.affiliate-card__icon {
  filter: grayscale(100%);
}
</style>
